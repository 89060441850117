@import 'src/shared/styles/typo';

.app-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 13px 29px;
  background-color: #cecece;
  cursor: pointer;
  border: none;
  min-width: 143px;
  border-radius: 2px;

  &:hover {
    filter: brightness(0.95);

    &:disabled {
      filter: none;
      box-shadow: none;
    }
  }

  &:active {
    filter: brightness(0.7);
    box-shadow: inset 0 0 2px #1d1d1d;

    &:disabled {
      filter: none;
      box-shadow: none;
    }
  }

  &.app-button_primary {
    background-color: #03bd82;

    .app-button__text {
      color: #fff;
    }
  }

  &.app-button_secondary {
    background-color: #c7c7c7;

    .app-button__text {
      color: rgb(92, 92, 92);
    }
  }

  &.app-button_black {
    background-color: #121b25;

    .app-button__text {
      color: #fff;
    }
  }

  &.app-button_bordered {
    padding: 11px 27px;
    border: solid 2px #00be82;
    background-color: transparent;

    .app-button__text {
      color: #00be82;
    }
  }

  &.app-button_oval {
    border-radius: 30px;
  }

  &.app-button_square {
    border-radius: 0;
  }

  &.app-button_centered {
    margin: 0 auto;
  }

  &.app-button_danger {
    background-color: #de6438;

    .app-button__text {
      color: #fff;
    }

    &.app-button_bordered {
      border: solid 2px #be4600;
      background-color: transparent;

      .app-button__text {
        color: #be4600;
      }
    }
  }

  &.app-button_large {
    padding: 20px 30px;

    .app-button__text {
      font-size: 20px;
    }
  }

  &.app-button_small {
    padding: 3px 5px 2px 5px;
    width: auto;
    min-width: 0;

    .app-button__text {
      font-size: 12px;
    }

    &.app-button_bordered {
      padding: 1px 5px 0 5px;
    }

  }

  &.app-button_icon-center {
    .app-button__icon {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  &.app-button_block {
    width: 100%;
  }

  &.app-button_disabled {
    background-color: #bababa;
    opacity: 0.6;
    cursor: default;
    color: #fff;
  }

  &.app-button_lowercase {
    .app-button__text {
      text-transform: lowercase;
    }
  }

  &.app-button_normalcase {
    .app-button__text {
      text-transform: unset;
    }
  }

  &.app-button_text {
    min-width: 0;
    background-color: transparent;
  }
}

.app-button__text {
  color: #000;
  font-size: 13px;
  font-weight: 550;
  text-transform: uppercase;
  font-family: $font-underground-small-caps;

  a {
    text-decoration: none;
    color: inherit;
  }
}

.app-button__icon {
}

.ant-input-group {
  .app-button {
    padding: 10px 29px;
    border-radius: 0 2px 2px 0;
  }
}
