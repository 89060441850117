.app-input {
  &.app-input_tiny-font {
    font-weight: 400;
    font-family: p22-underground, sans-serif;
  }
}

.list-search {
  background: #fff;
  display: flex;
  align-items: center;
  border: solid 1px #039965;
  padding-left: 15px;
}

.list-search__input {
}

.list-search__icon {

}
