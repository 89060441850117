.product-checkout {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 15px;
  //padding: 10px;
  border: solid 1px #bababa;
}

.product-checkout__title {
  flex: 5;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product-checkout__title-text {
  font-size: 14px;
}

.product-checkout__action {
  position: relative;
  width: 30px;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #323c47;
  cursor: pointer;
  svg {
    fill: #fff;
  }
  &.product-checkout__action_plus {
    background-color: #039965;
  }
  &.product-checkout__action_remove {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background-color: #ff5858;
    width: 4px;
    transition: width 200ms ease;
    &.product-checkout__action_remove-active {
      width: 30px;
      svg {
        display: flex;
      }
    }
    svg {
      display: none;
      fill: #fff;
    }
    &:hover {
      width: 30px;
      svg {
        display: flex;
      }
    }
  }
}

.product-checkout__price {
  align-self: stretch;
  justify-content: center;
  align-items: center;
  border-left: solid 1px #bababa;
  display: flex;
  flex: 1;
  flex-basis: 30px;
  .product-checkout__price-text {
    width: 100%;
    text-align: center;
    font-size: 11px;
  }
}

.product-checkout__quantity {
  display: flex;
  flex: 2;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input[type='number']:hover,
  input[type='number']:focus {
    -moz-appearance: spinner-textfield;
  }
  border-left: solid 1px #bababa;
  border-right: solid 1px #bababa;
  padding: 5px;
}

.product-checkout__quantity-input {
  width: 80px;
  height: 100%;
  border: none;
  font-size: 20px;
  &:focus {
    outline: none;
  }
}

.product-checkout__sum-input {
  width: 60px;
  height: 100%;
  border: none;
  font-size: 20px;
  &:focus {
    outline: none;
  }
}

.product-checkout__total {
  padding-left: 5px;
}


.product-checkout__gift {
  padding: 0 0 0 5px;
  display: flex;
  justify-content: center;
}

.product-checkout__gift-input {
  width: 35px;
  height: 100%;
  border: none;
  font-size: 12px;
  text-align: center;
  background-color: #f3f3f3;
  &:focus {
    outline: none;
  }
}