.app-spinner {
  position: relative;
  width: 43px;
  height: 43px;
  margin-left: -15px;
}

.app-spinner {
  div {
    transform-origin: 32px 22px;
    animation: 1.2s opaque ease-in-out infinite both;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 30px;
      width: 3px;
      height: 11px;
      border-radius: 30px;
      background-color: #55933e;
    }
  }
}

@mixin leafTransform($deg, $delay) {
  @if variable-exists(deg) {
    transform: rotate($deg + deg);
  }
  @if variable-exists(delay) {
    animation-delay: $delay + s;
  }
}

@mixin leaf($n, $deg, $delay: '') {
  &:nth-child(#{$n}) {
    @include leafTransform($deg, $delay);
  }
}

.app-spinner {
  div {
    @include leaf(1, 0);
    @include leaf(2, 45, 0.1);
    @include leaf(3, 90, 0.2);
    @include leaf(4, 135, 0.3);
    @include leaf(5, 180, 0.4);
    @include leaf(6, 225, 0.5);
    @include leaf(7, 270, 0.6);
    @include leaf(8, 315, 0.7);
  }
}

@keyframes opaque {
  0%,
  40%,
  100% {
    opacity: 0.1;
  }
  40% {
    opacity: 1;
  }
}
