$heading-font-family: futura-pt-bold, sans-serif;
$heading-font-style: normal;
$heading-font-weight: 700;

$font-futura-bold: futura-pt-bold, sans-serif;
$font-futura-medium: futura-pt-demi, sans-serif;
$font-underground-small-caps: p22-underground-sc, sans-serif;
$font-underground: p22-underground, sans-serif;

@mixin basic-heading {
  font-family: $heading-font-family;
  font-style: $heading-font-style;
  font-weight: $heading-font-weight;
}
h1 {
  font-family: $font-futura-bold;
}

h2 {
  @include basic-heading;
  margin-bottom: 30px;
}
