.ui-error {
  font-size: 18px;
  text-align: center;
  line-height: 21px;
  display: block;
}
.ui-result {
  font-size: 18px;
  text-align: center;
  line-height: 21px;
  display: block;
  color: #206c05;
}
