@import 'src/shared/styles/typo';
.modal-backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.modal-backdrop_show {
  display: flex;
}

.modal-backdrop_hide {
  display: none;
}

.modal-container {
  position: relative;
  width: 500px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.modal {
  background: #fff;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
}

.modal-header {
  position: relative;
  padding: 20px;
  display: flex;
  width: 100%;
  align-items: center;
}

.modal-body-wrapper {
  position: relative;
  flex: 1;
  overflow-y: auto;
  min-height: 200px;
  max-height: calc(100vh - 210px);
}

.modal-body {
  position: relative;
  padding: 30px 20px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-shadow: inset 0px 0px 3px #c4c4c4;
  &_square {
    min-height: 300px;
  }
}

.modal-close {
  cursor: pointer;
  position: absolute;
  right: 10px;
  padding: 10px 10px 5px 10px;
  background: transparent;
  &:hover {
    background: #d7d7d7;
  }
}

.modal-title {
  margin: 0;
  font-size: 21px;
  font-weight: 600;
  color: #ae2929;
  text-align: center;
  flex: 1;
  font-family: $font-futura-bold;
}

.modal-body-paragraph {
  text-align: center;
  font-size: 18px;
  color: #666666;
}
