.app-layout {
  width: 100%;
  min-height: 100vh;

  &.app-layout_manager {
    .app-layout-wrapper {
    }
  }

  &.app-layout_admin {
    .app-layout-wrapper {
      background: #0e2b00;
    }
  }

  &.app-layout_customer {
    .app-layout-wrapper {
      background: #000000;
    }
  }
}

.app-layout-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100vh;
  background: #09150b;
  padding: 5px;
}

.app-layout__sidebar {
  flex-basis: 260px;
  position: relative;
}

.app-layout__content {
  flex: 1;
  background: #fff;
  padding: 50px;
  position: relative;
  overflow: hidden;
}

@media (max-width: 450px) {
  .app-layout__content {
    padding: 8px;
  }
}

.app-layout__sidebar-burger {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
}

@media (max-width: 450px) {
  .app-layout__sidebar-burger {
    display: block;
  }
}

@media (min-width: 450px) {

  .app-layout__sidebar-burger {
    display: none;
  }
}
