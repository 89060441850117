.actions-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__spinner-place {
    height: 27px;
    margin-left: 10px;
    align-self: flex-start;
    display: flex;
    align-items: center;
  }
}
