.terms-and-conditions {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.terms-and-conditions__head {
}
.terms-and-conditions__body {
  padding: 15px;
  border: inset 14px #033e2a;
  flex: 1;
  overflow-y: auto;
}
.terms-and-conditions__footer {
}
.terms-and-conditions__actions {
  display: flex;
  justify-content: space-between;
  .app-button {
    border-radius: 0;
    flex: 1;
    min-height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      align-self: center;
      height: 20px;
    }
  }
}
