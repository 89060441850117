@media print {
  #print {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: white;
    width: 19cm;
    height: 27cm;
    justify-content: center;
    align-items: center;
    border: solid 1px black;

    img {
      align-self: center;
      width: 18cm;
      height: 18cm;
    }
  }
}
