.navigation-item-child {
  width: 100%;
  display: block;
  &.navigation-item-child_active {
    a {
      background-color: rgba(186, 186, 186, 0.3);
      color: #fff;
    }
  }
  a {
    display: block;
    color: #adadad;
    text-decoration: none;
    line-height: 2;

    padding-left: 5px;

    &:hover {
      background-color: rgba(186, 186, 186, 0.5);
      color: #fff;
    }
  }
}
