.member-photo {
  max-width: 370px;
}

.member-photo__image {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 4px;
}

.member-photo__image-source {
  width: 100%;
  height: 100%;
}
