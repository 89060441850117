.checkout {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.checkout__body {
  flex: 1;
  position: relative;
  overflow-y: auto;
  min-height: 180px;
}
.checkout__body-holder {
  position: absolute;
  width: 100%;
}
.checkout__footer {
  padding: 20px 0 0 0;
  border-top: dashed 1px #bababa;
}
.checkout__total {
  display: flex;
  justify-content: space-between;
}
.checkout__actions {
}

.checkout__header {
  padding-bottom: 20px;
  h4 {
    font-size: 18px;
    font-weight: 300;
  }
}

.checkout__discount {
  margin-bottom: 20px;
}
