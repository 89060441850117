$animationTime: 200ms;
.transition-translate-y-enter {
  opacity: 0;
  transform: translateY(-5px);
}
.transition-translate-y-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: opacity $animationTime ease-in-out, transform $animationTime ease-in-out;
}
.transition-translate-y-exit {
  opacity: 1;
  transform: translateY(0px);
}
.transition-translate-y-exit-active {
  opacity: 0;
  transform: translateY(-5px);
  transition: opacity $animationTime ease-in-out, transform $animationTime ease-in-out;
}

.transition-enter {
  opacity: 0;
}
.transition-enter-active {
  opacity: 1;
  transition: opacity $animationTime ease-in-out;
}
.transition-exit {
  opacity: 1;
}
.transition-exit-active {
  opacity: 0;
  transition: opacity $animationTime ease-in-out;
}
