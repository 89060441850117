.app-signature-pad {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.app-signature-pad__canvas {
  border: inset 14px #033e2a;

  flex: 1 1;
  //canvas {
  //  height: 100%;
  //  width: 100%;
  //}
}

.app-signature-pad__actions {
  text-align: center;
  flex-basis: 100px;
  min-height: 100px;


  * {
    &:last-child {
      margin-right: 0;
    }
  }

  display: flex;
  width: 100%;
  justify-content: space-between;

  .app-button {
    width: 100%;
    height: 100%;
    border-radius: 0;

    .ant-btn-primary {
    }
  }
}
