.app-profile {
}

.app-profile__photo {
  position: relative;
}

.app-profile__signature {
  position: absolute;
  height: 300px;
  width: 300px;
  left: 0;
  top: 65%;
  border: solid 1px black;
}

.app-profile__signature-pad {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  background-color: #fff;
  height: 100%;

  img {
    width: 100%;
  }
}

.app-profile__interviewed {
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  h3 {
    color: #2d9709;
    font-size: 18px;
    margin-top: 8px;
    font-weight: 700;
    margin-left: 20px;
  }
}

.app-profile__signature-image {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: #fff;
  height: 100%;
  display: flex;
  justify-content: center;
  align-self: center;

  img {
    max-height: 250px;
  }
}

.app-profile__photo-image {
  position: relative;
  width: 100%;
}

.app-profile__photo-action {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.2);
  top: 10px;
  right: 10px;
  cursor: pointer;
  padding: 5px 6px;
  line-height: 1;
  border-radius: 50%;
}

.app-profile__photo-action-icon {
}
