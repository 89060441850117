.app-discount {
  display: flex;
  align-items: center;
}
.app-discount-item {
  padding: 0 5px;
  display: flex;
  align-items: center;
  input {
    border: solid 1px black;
  }
}
.app-discount-item__label {
  margin-left: 5px;
}
