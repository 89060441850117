.app-sidebar {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.app-sidebar__section {
  &.app-sidebar__section_full-height {
    flex: 1;
  }
}
