@import 'src/shared/styles/typo';
.auth-page {
  width: 100%;
  height: 100%;
  background-color: #333333;
}

.auth-page-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.auth-page__body {
  width: 460px;
}

.auth-page__body-section {
  margin-bottom: 16px;
  &.auth-page__body-section_form {
    margin-bottom: 102px;
  }
  &.auth-page__body-section_header {
    margin-bottom: 90px;
  }
  &.auth-page__body-section_logo {
    margin-bottom: 88px;
  }
}

.auth-page__backdrop {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;

  background-size: cover;
  z-index: -100;
}

.auth-page__backdrop-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: rgba(18, 27, 37, 0.8);
}

.auth-page__logo-wrapper {
  display: flex;
  justify-content: center;
}

@media all and (max-height: 1200px) {
  .auth-page-wrapper {
    justify-content: flex-start;
  }
  .auth-page__body-section.auth-page__body-section_logo {
    margin-top: 80px;
  }
}

.form-heading {
  text-align: center;
  color: #fff;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 30px;
  font-family: $font-futura-bold;
}
.form-description {
  font-size: 14px;
  color: #fff;
  opacity: 0.5;
}
.form-subheading {
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}

.step-heading {
  text-align: center;
  color: #fff;
  opacity: 0.55;
  font-size: 14px;
}

.form-actions {
  margin-top: 60px;
  width: 350px;
  &_center {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin: 0 auto;
  }

  &_column {
    flex-direction: column;
    .app-button {
      margin-bottom: 10px;
    }
  }

  &_auto-width {
    width: auto;
  }
}

.form-async-validation-messages {
  display: flex;
  flex-direction: column;
  width: 370px;
  margin: 0 auto;
}

.form-action-link {
  margin-bottom: 10px;
  font-size: 13px;
  //'textAlign': 'center', 'color': '#ffffff'
  text-align: center;
  color: #fff;
  font-weight: bold;
}

.auth-page__email-confirmation-text {
  color: white;
  text-align: center;
}

.auth-page__invalid-field-list {
  color: #dd0000;
  li {
    font-size: 18px;
    margin-bottom: 12px;
    font-family: $font-underground-small-caps;
    font-style: normal;
    font-weight: 400;
  }
}

.fixed-form-block {
  position: fixed;
  left: 30px;
  bottom: 20px;
}
