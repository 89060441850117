.ant-form-horizontal .ant-form-item-label {
  text-align: left;

  label {
    &:after {
      display: none;
    }
  }
}

.ant-btn-primary {
  background-color: #039965;
  border-color: #039965;

  &:hover {
    background-color: #03c281;
    border-color: #03c281;
  }
}

.ant-switch-checked {
  background-color: #039965;
}

.ant-input {
  padding: 11px 13px;
  font-size: 16px;

  &:focus,
  &:hover {
    outline: #03c281;
    border-color: #03c281;
    box-shadow: 0 0 2px #03c281;
  }
}

.ant-picker-input > input {
  padding: 11px 13px;
  font-size: 16px;
}

.ant-picker-cell .ant-picker-cell-inner {
  font-size: 20px;
}

.ant-picker-header-view {
  font-size: 20px;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner {
  color: #fff;
  background: #03c281;

  &:before {
    border-color: #03c281;
  }
}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(
    .ant-picker-cell-range-end
  ):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
  .ant-picker-cell-inner {
  background: #015135;
  color: #fff;
}

.ant-switch {
  height: 27px;
}

.ant-switch-checked .ant-switch-inner {
  margin: 0 30px 0 7px;
}

.ant-switch-handle {
  top: 2px;
  left: 3px;
  width: 22px;
  height: 22px;
}

.ant-switch-handle,
.ant-switch-handle:before {
  position: absolute;
  transition: all 0.4s ease-in-out;
}

.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 24px);
}

.ant-switch-inner {
  font-weight: 600;
  font-size: 17px;
  margin: 0 7px 0 31px;
}

.ant-switch-handle:before {
  border-radius: 50%;
}

.ant-table {
  background: #f5f5f5;
  border-radius: 4px;
}

.ant-table-thead > tr > th {
  font-weight: 600;
}

.app-table-row_closed {
  background: #fff3f3;
}

.ant-table-row {
  &:hover {
    background-color: #c5ffeb;
  }
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: #c5ffeb;
}

.ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
}

.ant-tabs-card .ant-tabs-bar {
  border-color: #fff;
}

//.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab

.ant-tabs-card {
  &.ant-tabs-top {
    & > .ant-tabs-nav {
      .ant-tabs-tab {
        border-color: transparent;
        background: #03bd82;
        color: #fff;
        font-weight: 600;
        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: #03bd82;
          }
          border-color: #fff;
          background: #fff;
        }
      }
    }
  }
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  font-size: 18px;
}
.ant-form-item-label > label {
  font-size: 18px;
}


.ant-form-item-explain-error {
  font-size: 12px;
  text-align: right;
}