@import 'src/shared/styles/typo';
.app-input-active {
  background-color: rgba(255, 255, 255, 1);
  color: #121b25;

  ::placeholder,
  :-ms-input-placeholder,
  ::-ms-input-placeholder {
    opacity: 0.55;
  }
}

.app-input {
  @extend .app-input-active;
  width: 100%;
  border: none;
  padding: 21px 0 21px 24px;
  font-size: 16px;
  transition: background-color 0.3s ease-in-out;
  font-family: $font-futura-bold;

  &[disabled] {
    background: #e5e5e5;
    cursor: not-allowed;
  }

  &.app-input_focused {
    @extend .app-input-active;
  }

  &.app-input_active {
    @extend .app-input-active;
  }

  &.app-input_transparent {
    background-color: transparent;
    color: #fff;
  }

  &.app-input_core-test {
    padding: 5px;
  }
  &.app-input-mask_admin {
    color: #121b25;
    padding: 15px 10px;
    font-size: 14px;
  }

  &:focus {
    outline: none;
  }

  .rc-picker-input {
    border: none;
    background: none;

    & > input {
      border: none;
      background: none;
      font-size: inherit;

      &:focus {
        outline: none;
      }
    }
  }

  &.rc-picker-focused {
    border: none;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px #384750 inset;
  transition: background-color 5000s ease-in-out 0s;
}
