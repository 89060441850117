@import '../../../shared/styles/typo';
.patient-search-form {
  width: 400px;

  .form-item__control {
    background-color: #e5e5e5;
  }

  .rc-select {
    border: 1px solid #121b25;
  }
}

.patient-search-form-avatar {
  margin-right: 10px;
}

.patient-search-title {
  font-size: 26px;
  text-align: center;
  margin-bottom: 54px;
  @include basic-heading;
}

.patient-search-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: -75px;
}

.patient-search-statuses__head {
  width: 100%;
  justify-content: flex-end;
  flex-direction: column;
  height: 33%;
  margin-bottom: 130px;
  display: flex;
}

.patient-search-statuses__body {
  display: flex;
  align-items: self-start;
  justify-content: center;
  height: 66%;
  padding: 0 30px;
}

.patient-search-statuses__title {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  @include basic-heading;
}

.pathway-line-chart {
  height: 150px;
}

.back-icon {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.patient-search-select__label {
  cursor: default;
  text-transform: uppercase;
  margin-bottom: 16px;
  font-weight: 600;
}
.app-input {
  &.app-input_dark {
    background: #1d1d1d;
    color: #fff;
  }
}

.listing-hr {
  margin-bottom: 15px;
}
