@import url('https://fonts.googleapis.com/css2?family=Palanquin+Dark:wght@700&family=Roboto:wght@100;300;700&display=swap');
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  height: 100%;
}

html {
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

.app-wrapper {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
