.member-interview-finally {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.member-interview-finally__center {
  width: 400px;
  text-align: center;

  h1 {
    color: #476805;
  }
  h3 {
    color: #6a9a09;
  }
}
.member-interview-finally__center-icon {
  text-align: center;
  margin-bottom: 30px;
}
