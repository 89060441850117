.sidebar-logo {
  margin-top: 60px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  &.sidebar-logo_admin {
    .sidebar-logo__text {
      color: #c8b2a4;
    }
  }
}

.sidebar-logo__text {
  font-size: 30px;
  color: #039965;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'Palanquin Dark', sans-serif;
  padding: 13px 0 20px 0;
  border-top: dotted 3px #00744c;
  border-bottom: dotted 3px #00744c;

  word-break: break-word;
  line-height: 1;
  text-align: center;
}
