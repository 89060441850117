.preloader-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  &_over {
    background-color: rgba(255, 255, 255, 0.5);
  }
  &_lower {
    position: absolute;
    z-index: 10;
    max-height: 100vh;
  }
  &_transparent {
    background-color: transparent;
  }
}

.preloader-wrapper__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.preloader-wrapper__message {
  color: #039965;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-top: 20px;
}
