.common-page-section {
  margin-bottom: 70px;
  &.common-page-section_flex {
    .common-page-section__body {
      display: flex;
    }
  }
}
.common-page-section__head {
  display: flex;
  margin-bottom: 17px;
  align-items: center;
}
.common-page-section__actions {
  flex-grow: 1;
}
.common-page-section__title {
  font-size: 28px;
  line-height: 30px;
  color: #121b25;
  font-weight: 300;
}

.common-page-section__body {
}

.common-page-section__icon {
  margin-bottom: 20px;
}

.common-page-section__footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
}
