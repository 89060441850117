.app-cashier {
  display: flex;
  flex: 1;
}
.app-cashier__product-list {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  align-content: flex-start;
  flex-basis: 70%;
  height: 100%;
}
.app-cashier__checkout-list {
  height: 100%;
  min-width: 430px;
  border-left: solid 1px #bababa;
  padding: 0 0 0 15px;
  flex: 1;
  flex-basis: 25%;
}
