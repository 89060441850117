.navigation-item {
  position: relative;
  border-radius: 6px;
  display: flex;
  border-bottom: solid 1px rgb(68, 70, 94);
  background: rgba(90, 97, 99, 0.2);
  margin-bottom: 5px;
  transition: background-color 100ms ease-in-out;
  &:hover {
    background: rgba(90, 97, 99, 0.5);
  }
  &:before {
    content: '';
    width: 60px;
    height: 100%;
    border-radius: 2px;
    background: #039965;
    border-right: solid 1px #06de93;
    position: absolute;
    display: none;
  }
  &.navigation-item_active {
    //background: rgba(212, 239, 252, 0.06);
    background: rgb(212, 239, 252);
    background: linear-gradient(
      90deg,
      rgba(212, 239, 252, 1) 0.06%,
      rgba(212, 239, 252, 0) 0.06%,
      rgba(131, 139, 189, 0.34) 0.06%
    );
    border-bottom: solid 1px rgb(131, 139, 189);
    &:before {
      display: block;
    }
  }
  &_disabled {
    cursor: default;
  }
  &.navigation-item_admin {
    &:before {
      background-color: #2f2121;
    }
  }
  &.navigation-item_manager {
    &:before {
    }
  }
  &.navigation-item_customer {
    &:before {
      background-color: #a54500;
    }
  }
}

.navigation-item-expandable {
  margin-bottom: 10px;
}

.navigation-item-wrapper {
  display: flex;
  flex-direction: column;
  padding: 15px 15px 15px 20px;
  width: 100%;
  text-decoration: none;
  &:active {
    text-decoration: none;
  }
}

.navigation-item-container {
  display: flex;
  align-items: center;
  padding-left: 55px;
}

.navigation-item__text {
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  flex: 1;
  text-align: right;
}

.navigation-item__icon {
  position: absolute;
  display: flex;
  left: 20px;
  svg {
    width: 20px !important;
    height: 20px !important;
    font-size: 20px !important;
  }
}

.navigation-item__postfix {
  margin-left: 10px;
  display: flex;
  svg {
    fill: #fff;
  }
}

.navigation-item-items {
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  padding-left: 55px;
  margin-top: 10px;
}
