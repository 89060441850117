.current-user__wrapper {
  position: relative;
}

.current-user__container {
  display: flex;
  justify-content: flex-end;
}

.current-user__anchor {
  cursor: pointer;
  margin-left: 10px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  color: #959595;
  padding-bottom: 5px;
  text-decoration: underline;
  &:hover {
    border-color: #fff;
    color: #fff;
  }
  &:first-child {
    margin-left: 0;
  }
}

.current-user__text {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 14px;
  color: white;
  margin-bottom: 10px;
}

.confirm-sign-out-modal {
  position: absolute;
  top: calc(-115px - 30px);
  left: -24px;
  background-color: #fff;
  z-index: 15;
  white-space: nowrap;

  border: 1px solid rgba(18, 27, 37, 0.17);
  border-radius: 24px;

  &__header {
    //border-bottom: 1px solid rgba(18, 27, 37, 0.17);
    line-height: 14px;
    font-family: futura-pt-bold, sans-serif;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #121b25;
    padding: 26px 12px 0 12px;
  }

  &__body {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 26px 12px 15px;

    .triangle-icon {
      width: 22px !important;
      height: 19px !important;
      position: absolute;
      bottom: -22px;
      left: 40px;
      bottom: -15px;
    }
  }

  &__icon {
    margin-right: 18px;
  }
}
