.app-page {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  min-height: 100%;
  &.app-page_shadowed {
    box-shadow: 0 4px 12px rgba(18, 27, 37, 0.17);
    padding: 20px;
  }
  &.app-page_centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.common-page__head {
  margin-bottom: 20px;
  display: flex;
}
.common-page__actions {
  margin-left: auto;
  display: flex;
  .app-button {
    margin-left: 6px;
  }
}

.common-page__body {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.common-page__title {
  font-size: 24px;
  line-height: 27px;
  color: #121b25;
  font-weight: 300;
}
