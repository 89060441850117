.member-info {
  &.member-info_short {
    .member-info__item {
      font-size: 12px;
    }
    .member-info__item-key {
      font-size: 12px;
    }
    .member-info__item-value {
      font-size: 12px;
      font-weight: 600;
    }
  }
}

.member-info__item {
  margin-bottom: 6px;
  padding-bottom: 6px;
  font-size: 16px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: dashed 1px #b6b6b6;
  &_solo {
    padding: 0;
    margin-bottom: 0;
    border: none;
  }
  &_quote {
    flex-direction: column;
    margin-bottom: 0;
    align-items: flex-start;
    justify-content: center;
    border: none;
  }
}

.member-info__item-key {
  font-size: 16px;
}

.member-info__item-value {
  font-size: 18px;
  font-weight: 600;
}

.member-info__name {
  font-size: 36px;
  font-weight: 600;
  line-height: 1;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  border-bottom: solid 2px #494949;
  * {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
      align-self: flex-start;
    }
  }
}

.member-info__balance {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding: 15px 15px 23px 15px;
  border-radius: 4px;
  background: #000000;
  color: #fff;
  &.member-info__balance_short {
    background-color: #515151;
    padding: 15px;
    margin-top: 30px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.member-info__balance-key {
  font-size: 16px;
}

.member-info__item-key {
  margin-right: 10px;
}

.member-info__balance-value {
  margin-top: 5px;
  font-size: 36px;
  font-weight: 600;
}

.member-info__balance-actions {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.member-info__balance-action {
  display: flex;
  justify-content: center;
}

.member-info__balance-action-icon {
  margin-right: 6px;
  svg {
    fill: #000;
  }
}

.member-info__balance-action-text {
  font-size: 16px;
  font-weight: 600;
}
