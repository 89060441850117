.member-profile-short {
}

.member-profile-short__photo {
  margin-bottom: 26px;
}

.member-profile-short__name {
  font-size: 30px;
  line-height: 1;
  font-weight: 600;
}

.member-profile-short__credit {
}

.member-profile-short__actions {
  display: flex;
}
