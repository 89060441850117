.products-list {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 80vh;
}

.products-list__header {
}

.products-list__body {
  flex: 1;
  overflow: scroll;
}

.products {
  display: flex;
  flex-wrap: wrap;
}

.product-wrapper {
  position: relative;
  margin: 0 8px 8px 0;
}

.product {
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background: #fff;
  border: solid 1px #039965;
  padding: 8px 8px 0 8px;
  width: 11rem;
  min-height: 6rem;
  border-radius: 6px;
  box-shadow: 0 0 0 rgba(0, 190, 130, 0);
  transition: box-shadow 100ms ease;

  &:active {
    box-shadow: 0 0 20px rgba(0, 190, 130, 0.5);
  }

  &.product_checkout-view {
    height: auto;
    width: auto;
    padding: 5px;
    margin: 0;
    border: none;
  }
}

.product__header {
  border-bottom: solid 1px #039965;
  padding-bottom: 10px;
}

.product__header-title {
  font-size: 16px;
  line-height: 1;
}

.product__body {
  display: flex;
  flex: 1;
}

.product__body__description {
}

.product__footer {
}

.product__price {

}

.product-with-quantity {
  display: flex;
}

.product-with-checkout-controls {
  display: flex;
  position: relative;
  margin-bottom: 20px;
  border: solid 1px black;
}

.product-with-checkout-controls__container {
}

.product-with-checkout-controls__remove {
  position: absolute;
  top: 0;
  right: 0;
}

.product__out-of-stock {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(217, 217, 217, 0.8);
  border: solid 1px red;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 700;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  * {
  }
}
