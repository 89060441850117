.app-sidebar-section {
  &.app-sidebar-section_full-height {
    flex: 1;
    position: relative;
    overflow-y: auto;
  }

  &.app-sidebar-section_profile-widget {
    padding: 25px;
    background: #1d1d1d;
  }
}
