// TODO: Remove this file after all styles moved to components
@import 'grid';
@import './common/actions-wrapper.scss';

.app-wrapper {
  width: 100%;
  height: 100%;
}

.app-section {
  margin-bottom: 15px;

  &.app-section_new-patient-form {
    margin-bottom: 80px;
  }

  &.app-section_center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.app-section_margin-xl {
    margin-bottom: 45px;
  }
}
@import './common/antd.overrides.scss';
@import './responsive/all';
