.grid-row {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
  &_admin-form {
    justify-content: space-evenly;
    .grid-col {
      &.grid-col_skinny {
        width: 370px;
      }
    }
  }
}
.grid-col {
  padding-left: 10px;
  padding-right: 10px;
  &_wide {
    flex: 1;
  }
}
