.app-checkbox {
  position: relative;
  display: inline-block;
  vertical-align: middle;

  width: 20px;
  height: 20px;
}

.app-checkbox-hidden {
  left: 0;
  top: 0;
  border: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
  position: absolute;
  white-space: nowrap;
  opacity: 0;
  cursor: pointer;
}

.app-checkbox-styled {
  display: inline-block;
  width: 20px;
  height: 20px;
  transition: all 150ms;
  border: solid 1px #121b25;
  border-radius: 2px;
  background-color: #fff;
  &_checked {
    background-color: #121b25;
    .app-checkbox-styled__checkmark {
      display: flex;
    }
  }
}

.app-checkbox-styled__checkmark {
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
}

.labeled-checkbox {
  cursor: pointer;
  display: flex;

  align-items: center;
}
.labeled-checkbox__text {
  margin-left: 5px;
  font-size: 18px;
  font-weight: 900;
}
